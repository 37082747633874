.faqcontainer {
  margin-top: 10rem;
  margin-bottom: 8rem;
  padding-left: 1rem;
  position: relative;
  align-items: center;
  width: 100%;
}

.tabscontainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}