.code-2-create {
  position: relative;
  height: 916px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-box {
  position: absolute;
  width: 1189.91px;
  height: 569.11px;
}

.arrow-small-container {
  position: absolute;
  width: 62.15px;
  height: 56.06px;
  left: 143.37px;
  top: 0px;
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 42.17px;

  position: absolute;
  width: 717px;
  height: 308.77px;
  left: 470.16px;
  top: 98.04px;
}

.content-box-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  width: 717px;
  height: 126px;
}

.content-box-title {
  width: 501px;
  height: 126px;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 84.2913px;
  line-height: 150%;
  /* or 126px */

  letter-spacing: -0.02em;

  color: #5ebf94;
}

.content-box-text {
  width: 717px;
  height: 90px;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 29.8273px;
  line-height: 150%;
  /* or 45px */

  letter-spacing: -0.02em;

  color: #ffffff;
}

.content-box-input {
  position: relative;
  top: 210px;
}

.content-box-input > input {
  box-sizing: border-box;

  position: absolute;
  width: 296.22px;
  height: 50.6px;
  padding: 1rem;
  background: #ffffff;
  border: 2.10835px solid #5ebf94;
  top: 0;
  left: 0;
  border-radius: 12.6501px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 16.8668px;
  line-height: 23px;
  color: black;
}

.content-box-input-button {
  position: absolute;
  width: 152.86px;
  height: 50.6px;
  left: 304.66px;
  top: 0px;

  background: #5ebf94;
  border-radius: 12.6501px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-box-input-button > button {
  position: absolute;
  width: 84px;
  height: 23px;
  left: 34.79px;
  top: 13.7px;

  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16.8668px;
  line-height: 23px;
}

.big-logo {
  position: absolute;
  width: 377.39px;
  height: 417.83px;
  left: 0px;
  top: 112.8px;
}

.arrow-big-container {
  position: absolute;
  width: 149.44px;
  height: 160.09px;
  left: 1040.47px;
  top: 415px;
}

@media all and (max-width: 1365.99px) {
  .code-2-create {
    height: 559.98px;
  }

  .main-box {
    position: absolute;
    width: 689.16px;
    height: 329.61px;
  }

  .arrow-small-container {
    position: absolute;
    width: 35.99px;
    height: 32.47px;
    left: 83.03px;
    top: 0px;
  }

  .content-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24.42px;

    position: absolute;
    width: 372px;
    height: 215.73px;
    left: 272.3px;
    top: 56.78px;
  }
  .content-box-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    width: 372px;
    height: 162px;
  }
  .content-box-title {
    width: 285px;
    height: 72px;

    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 150%;
    /* identical to box height, or 72px */

    letter-spacing: -0.02em;

    color: #5ebf94;
  }
  .content-box-text {
    width: 372px;
    height: 90px;

    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    /* or 30px */

    letter-spacing: -0.02em;

    color: #ffffff;
  }
  .content-box-input {
    position: relative;
    top: 150px;
  }
  .content-box-input > input {
    box-sizing: border-box;

    position: absolute;
    width: 171.56px;
    height: 29.31px;
    left: 0px;
    top: 0px;

    background: #ffffff;
    border: 1.22108px solid #5ebf94;
    border-radius: 7.3265px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    padding: 0.5rem;
    display: flex;
    align-items: center;

    color: #000000;
  }

  .content-box-input-button {
    position: absolute;
    width: 88.53px;
    height: 29.31px;
    left: 176.45px;
    top: 0px;

    background: #5ebf94;
    border-radius: 7.3265px;
  }
  .content-box-input-button > button {
    position: absolute;
    width: 33px;
    height: 16px;
    left: 27.21px;
    top: 7.07px;

    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .big-logo {
    position: absolute;
    width: 218.57px;
    height: 241.99px;
    left: 0px;
    top: 65.33px;
  }
  .arrow-big-container {
    position: absolute;
    width: 86.55px;
    height: 92.72px;
    left: 602.6px;
    top: 336.89px;
  }

  @media all and (max-width: 650px) {
    .code-2-create {
      height: 500px;
    }
    .main-box {
      position: absolute;
      width: 336px;
      height: 351.68px;
    }
    .arrow-small-container {
      position: absolute;
      width: 16.18px;
      height: 14.6px;
      left: 28px;
      top: 0px;
    }
    .content-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 10.98px;

      position: absolute;
      width: 336px;
      height: 158.98px;
      left: 0px;
      top: 192.7px;
    }
    .content-box-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;

      width: 336px;
      height: 120px;
    }
    .content-box-title {
      width: 190px;
      height: 48px;

      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 150%;
      /* identical to box height, or 48px */

      letter-spacing: -0.02em;

      color: #5ebf94;
    }
    .content-box-text {
      width: 336px;
      height: 72px;

      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      /* or 24px */

      letter-spacing: -0.02em;

      color: #ffffff;
    }
    .content-box-input {
      position: static;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 24px;

      width: 281px;
      height: 28px;
      position: relative;
      top: 100px;
    }
    .content-box-input > input {
      position: static;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
      gap: 8px;

      width: 184px;
      height: 28px;

      background: #ffffff;
      border: 0.971961px solid #5ebf94;
      border-radius: 5.83177px;
    }
    .content-box-input-button {
      position: static;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 20px;
      gap: 8px;

      width: 73px;
      height: 28px;

      background: #5ebf94;
      border-radius: 7.75975px;
    }
    .content-box-input-button > button {
      position: static;
      width: 33px;
      height: 16px;

      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }
    .big-logo {
      position: absolute;
      width: 144.67px;
      height: 160.17px;
      left: 95.67px;
      top: 11.53px;
    }
    .arrow-big-container {
      position: absolute;
      width: 38.92px;
      height: 41.69px;
      left: 297px;
      top: 450px;
    }
  }
}
