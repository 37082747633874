@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

.question-section {
  background: white;
  cursor: pointer;
  width: 70%;
  margin: 1px;
  padding: 20px;
}
.odd:hover {
  background: #ff007a;
  color: white;
}
.even:hover {
  background: #7f30ff;
  color: white;
}
.active-odd {
  background: #ff007a;
  color: white;
}
.active-even {
  background: #7f30ff;
  color: white;
}

.question-title {
  margin-top: 10px;
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 22px;
  text-align: left;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.question-icon {
  height: auto;
  margin-right: 3%;
  background-color: #f3f5f6;
  border-radius: 50%;
  color: #333333;
  padding: 7.5px;
  cursor: pointer;
  font-size: 35px;
  margin-left: auto;
  transition: transform 0.3s ease;
}

.answer-icon {
  height: auto;
  margin-right: 3%;
  background-color: black;
  border-radius: 50%;
  color: #ffffff;
  padding: 7.5px;
  cursor: pointer;
  font-size: 35px;
  margin-left: auto;
  transition: transform 0.3s ease;
  transform: rotate(45deg);
}

.answer {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 135px;
  padding-right: 55px;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #eeeeee;
}

.number {
  margin-left: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.25px;
  color: rgba(60, 60, 67, 0.5);
}

@media only screen and (max-width: 768px) {
  .question-section {
    width: 80%;
  }
  .question-title {
    margin-top: 10px;
    font-size: 20px;
  }
  .answer {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 100px;
    padding-right: 55px;
    font-size: 14px;
  }
  .number {
    margin-left: 10px;
    font-family: "Inter";
    font-size: 36px;
  }
  .question-icon {
    margin-right: 2%;
  }
  .answer-icon {
    margin-right: 2%;
  }
}

.ques_title {
  margin-left: 40px;
}
