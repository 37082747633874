.horizontal-box {
  position: absolute;
  width: calc(var(--page-width-factor) * 1366px);
  height: calc(var(--page-height-factor) * 916px);
}
.horizontal-content-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: calc(var(--page-width-factor) * 120px);

  position: absolute;
  width: calc(var(--page-width-factor) * 934px);
  height: calc(var(--page-height-factor) * 431px);
  left: calc(var(--page-width-factor) * 139.99px);
  top: calc(var(--page-height-factor) * 150px);
}
.big-number {
  font-family: "Clash Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: calc(var(--page-width-factor) * 350px);
  line-height: calc(var(--page-height-factor) * 431px);

  color: #ffffff;
}
.horizontal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: calc(var(--page-height-factor) * 32px);
  width: calc(var(--page-width-factor) * 687px);
  height: calc(var(--page-height-factor) * 256px);
}
.horizontal-content-title {
  width: 185px;
  height: 104px;

  font-family: "Clash Grotesk";
  font-style: normal;
  font-weight: calc(var(--page-width-factor) * 600);
  font-size: calc(var(--page-width-factor) * 80px);
  line-height: calc(var(--page-width-factor) * 98px);

  color: #ffffff;
}
.horizontal-content-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: calc(var(--page-width-factor) * 32px);
  line-height: calc(var(--page-width-factor) * 42px);
  color: #ffffff;
}

.image-container {
  padding-top: 15rem;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
}
.strip-image {
  width: 100%;
}
@media all and (max-width: 500px) {
  .horizontal-box {
    width: 100vw;
    height: auto;
    position: relative;
  }
  .image-container {
    display: none;
  }
  .horizontal-content-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 32.94px;

    position: absolute;
    width: 304.94px;
    height: 152.78px;
    left: 35px;
    /* top: 35px; */
  }
  .big-number {
    width: 35px;
    height: 119px;

    font-family: "Clash Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 96.5928px;
    line-height: 119px;
    /* identical to box height */

    color: #ffffff;
  }
  .horizontal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 8.78px;

    width: 237px;
    height: 152.78px;
  }
  .horizontal-content-title {
    width: 71px;
    height: 39px;

    font-family: "Clash Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;

    color: #ffffff;
  }
  .horizontal-content-text {
    width: 237px;
    height: 105px;

    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    color: #ffffff;
  }
}
