@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.ring-first {
  transform-origin: center center;
  animation: wiggle 1500ms ease-out infinite forwards;
}

.ring-second {
  transform-origin: center center;
  animation: wiggle 1750ms ease-out infinite forwards;
}

.ring-third {
  transform-origin: center center;
  animation: wiggle 2000ms ease-out infinite forwards;
}

#registerBtn {
  right: 2%;
  bottom: 34%;
}

@media (min-width: 475px) {
  #registerBtn {
    right: 12%;
    bottom: 35%;
  }
}

@media (min-width: 640px) {
  #registerBtn {
    right: 12%;
    bottom: 36%;
  }
}

@media (min-width: 768px) {
  #registerBtn {
    right: 12%;
    bottom: 34%;
  }
}

@media (min-width: 1024px) {
  #registerBtn {
    right: 20%;
    bottom: 30%;
  }
}
