.slide {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.inner-wrapper {
  display: flex;
  flex-direction: row;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}

.outer-wrapper {
  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.outer-outer-wrapper {
  position: relative;
  height: 100vh;
}
@media all and (max-width: 500px) {
  .outer-wrapper {
    width: 100vw;
    height: auto;
    transform: none;
    transform-origin: top left;
  }
  .inner-wrapper {
    flex-direction: column;
    transform: none;
    width: 100vw !important;
  }
  .outer-outer-wrapper {
    height: auto;
  }
  .slide {
    height: calc(100vw * 0.75);
  }
}
