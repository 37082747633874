@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400&700&900&display=swap");

@font-face {
  font-family: "clash_grotesk_variable";
  src: url("./assets/fonts/clashgrotesk-variable-webfont.woff") format("woff2"),
    url("./assets/fonts/clashgrotesk-variable-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.app {
  background-color: #171717;
  color: white;
  background-image: url("./assets/images/grid.svg");
  background-repeat: repeat;
  background-size: 35%;
  cursor: url("./assets/images/cursor.svg"), all-scroll;
}

.text-title {
  font-family: "clash_grotesk_variable", "Segoe UI", "Roboto", sans-serif;
}

.text-para {
  font-family: "DM Sans", sans-serif;
}

.grecaptcha-badge {
  visibility: hidden;
}
